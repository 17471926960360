// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-howtovote-bn-js": () => import("./../../../src/pages/howtovote.bn.js" /* webpackChunkName: "component---src-pages-howtovote-bn-js" */),
  "component---src-pages-howtovote-en-js": () => import("./../../../src/pages/howtovote.en.js" /* webpackChunkName: "component---src-pages-howtovote-en-js" */),
  "component---src-pages-howtovote-es-js": () => import("./../../../src/pages/howtovote.es.js" /* webpackChunkName: "component---src-pages-howtovote-es-js" */),
  "component---src-pages-howtovote-js": () => import("./../../../src/pages/howtovote.js" /* webpackChunkName: "component---src-pages-howtovote-js" */),
  "component---src-pages-index-bn-js": () => import("./../../../src/pages/index.bn.js" /* webpackChunkName: "component---src-pages-index-bn-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-es-js": () => import("./../../../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-joinus-bn-js": () => import("./../../../src/pages/joinus.bn.js" /* webpackChunkName: "component---src-pages-joinus-bn-js" */),
  "component---src-pages-joinus-en-js": () => import("./../../../src/pages/joinus.en.js" /* webpackChunkName: "component---src-pages-joinus-en-js" */),
  "component---src-pages-joinus-es-js": () => import("./../../../src/pages/joinus.es.js" /* webpackChunkName: "component---src-pages-joinus-es-js" */),
  "component---src-pages-joinus-js": () => import("./../../../src/pages/joinus.js" /* webpackChunkName: "component---src-pages-joinus-js" */),
  "component---src-pages-platform-bn-js": () => import("./../../../src/pages/platform.bn.js" /* webpackChunkName: "component---src-pages-platform-bn-js" */),
  "component---src-pages-platform-en-js": () => import("./../../../src/pages/platform.en.js" /* webpackChunkName: "component---src-pages-platform-en-js" */),
  "component---src-pages-platform-es-js": () => import("./../../../src/pages/platform.es.js" /* webpackChunkName: "component---src-pages-platform-es-js" */)
}

