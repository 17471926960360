import React, { useMemo } from 'react';
import AssetsContext from './assetsContext';
import { useStaticQuery, graphql } from 'gatsby';

const AssetsContainer = ({ children }) => {
  const queryOutput = useStaticQuery(graphql`
    query assets {
      file: allFile {
        edges {
          node {
            relativePath
            publicURL
          }
        }
      }
      site: site {
        siteMetadata {
          fonts {
            fontName
            fileName
          }
          footerEmail
          socialAccounts {
            iconName
            url
          }
        }
      }
      imagesMaxFixed: allImageSharp {
        nodes {
          fixed {
            ...GatsbyImageSharpFixed_withWebp
            originalName
          }
          original {
            height
            width
          }
        }
      }
      imagesMax: allImageSharp {
        nodes {
          fluid(maxWidth: 1980) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
          original {
            height
            width
          }
        }
      }
      imagesSmallWidened: allImageSharp {
        nodes {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
          original {
            height
            width
          }
        }
      }
      navImages: allFile(filter: {relativePath: {regex: "/sandy-header-logo/"}}) {
        edges {
          node {
            id
            name
            childImageSharp {
              fixed(height: 50){
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
        }
      }
      imagesSmallHeightened: allImageSharp {
        nodes {
          fluid(maxHeight: 400) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
          original {
            height
            width
          }
        }
      }
      images: allImageSharp {
        nodes {
          original {
            height
            width
            src
          }
          resize {
            originalName
          }
        }
      }
    }
  `);

  const files = queryOutput.file;
  const siteMetadata = queryOutput.site.siteMetadata;
  const fonts = queryOutput.site.siteMetadata.fonts;
  const imagesMax = queryOutput.imagesMax;
  const imagesWidened = queryOutput.imagesSmallWidened;
  const imagesHeightened = queryOutput.imagesSmallHeightened;
  const imagesMaxFixed = queryOutput.imagesMaxFixed;
  const navImages = queryOutput.navImages;
  const fileUrls = {};

  files.edges.forEach((edge) => {
    fileUrls[edge.node.relativePath] = edge.node.publicURL;
  });

  const mapImageQueryOutput = useMemo(() => (nodes, staticPath) => {
    const response = {};
    nodes.forEach((node) => {
      response[node[staticPath].originalName] = { ...node[staticPath], ...node.original && {original: node.original }};
    });
    return response;
  }, [])

  const imagesMaxByName = mapImageQueryOutput(imagesMax.nodes, 'fluid')
  const imagesWidenedByName = mapImageQueryOutput(imagesWidened.nodes, 'fluid')
  const imagesHeightenedByName = mapImageQueryOutput(
    imagesHeightened.nodes,
    'fluid'
  )
  const imagesMaxFixedByName = mapImageQueryOutput(
    imagesMaxFixed.nodes,
    'fixed'
  )
  const navImagesByLang = {
    en: navImages.edges.filter(edge => edge.node.name === 'sandy-header-logo-en')[0].node.childImageSharp.fixed,
    es: navImages.edges.filter(edge => edge.node.name === 'sandy-header-logo-es')[0].node.childImageSharp.fixed,
    bn: navImages.edges.filter(edge => edge.node.name === 'sandy-header-logo-en')[0].node.childImageSharp.fixed

  }

  return (
    <AssetsContext.Provider
      value={{
        fileUrls,
        fonts,
        images: queryOutput.images,
        imagesMaxByName,
        imagesWidenedByName,
        imagesHeightenedByName,
        imagesMaxFixedByName,
        navImagesByLang,
        siteMetadata,
      }}
    >
      <div>{children}</div>
    </AssetsContext.Provider>
  );
};

export default AssetsContainer;
