module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sandy Nurse for New York City Council","short_name":"Sandy for Council","icons":[{"src":"/icons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/icons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-localization/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","es","bn"],"namespaces":["translation","index","platform","joinus","howtovote"],"localesDir":"./src/locales","allowIndex":false,"defaultLng":"en","suspenseFallback":"/home/timfitzgerald/n4ny/src/components/fallback.js","embedTranslations":{"preloadFallbackLng":true,"preloadNamespaces":[{"regex":"/.*/","namespaces":["translation","index"],"languages":["en","es","bn"]},{"regex":"/platform/","namespaces":["translation","platform"],"languages":["en","es","bn"]},{"regex":"/joinus/","namespaces":["translation","joinus"],"languages":["en","es","bn"]},{"regex":"/howtovote/","namespaces":["translation","howtovote"],"languages":["en","es","bn"]}]},"i18next":{"fallbackLng":"en","react":{"wait":true,"useSuspense":true},"debug":false},"i18nPlugin":{"langKeyDefault":"en","useLangKeyLayout":false}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
