const React = require('react');

const AssetsContextWrapper = require('./src/components/assetsContextWrapper').default;

exports.wrapPageElement = ({ element }) => {

    return (
        <AssetsContextWrapper>
            {element}
        </AssetsContextWrapper>
    );
};